"use strict";
import {findIn} from '@elements/dom-utils';
import {onFind, initInScope} from "@elements/init-modules-in-scope";
import axios from "axios";
import {onEnterViewPort} from "@elements/viewport-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import * as scrollSlider from './scroll-slider';
// import * as counter from './counter.js';

export function init() {
    onFind('.js-async-slider', function (element) {
        onEnterViewPort(element, () => {
            let asyncArea = findIn('.js-async-slider__area', element);
            let pendingRequest = axios({method: 'POST', url: getPrefixedDataSet('async-slider', element).url });

            pendingRequest.then((response) => {
                if(response.data.success === true){
                    const result = response.data.html;

                    let markupElement = document.createElement('div');
                    markupElement.innerHTML = result;
                    let newSlides = markupElement.childNodes;
                    newSlides.forEach((item) => {
                        asyncArea.appendChild(item);
                    });

                    initInScope(asyncArea);
                    scrollSlider.init();
                    // counter.init();
                }
            });
        });
    });
}